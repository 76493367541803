import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Headroom from 'headroom.js';

const MENU_BREAKPOINT = '(min-width: 48em)'; // >= 768px
const KEYCODE_TAB = 9;

class Menu {
	constructor($el) {
		if (!$el) {
			return;
		}

		this.$el = $el;
		this.$open = this.$el.querySelector('.js-menu__open');
		this.$close = this.$el.querySelector('.js-menu__close');
		this.$popup = this.$el.querySelector('.js-menu__popup');
    this.$scroller = this.$el.querySelector('.js-menu__scroller');
		this._focusTrapCallback = this.getFocusTrapCallback();
    this.$$links = this.$el.querySelectorAll('.js-menu__link');

    this.$metaThemeColor = document.querySelector('meta[name="theme-color"]');
    this.originalThemeColor = this.$metaThemeColor.getAttribute('content');

		this.headroom = new Headroom(this.$el, {
			offset: 300,
			tolerance: {
				up: 5,
				down: 0,
			},
			classes: {
				initial: 'has-headroom',
				pinned: 'is-pinned',
				unpinned: 'is-unpinned',
				top: 'is-top',
				notTop: 'is-not-top',
				bottom: 'is-bottom',
				notBottom: 'is-not-bottom',
				frozen: 'is-frozen',
			},
		});
		this.headroom.init();

		this._closeCallback = (e) => {
			if (this.$el.contains(e.target)) {
				// do not close menu when clicking inside
				return;
			}

			this.toggle(false );
		};

		this.$open.addEventListener('click', this.toggle.bind(this, true));
		this.$close.addEventListener('click', this.toggle.bind(this, false));

		this.mediaQuery = matchMedia(MENU_BREAKPOINT);
    this.mediaQuery.addEventListener('change', (query) => this.setDesktopMode(query.matches));
    this.setDesktopMode(this.mediaQuery.matches);

		this.$el.addEventListener('keydown', (e) => {
			if (!this.isOpen) {
				return;
			}

			if (e.key === 'Escape') {
				this.toggle(false);
			}
		});

    this.$el.addEventListener('focusin', (e) => {
      this.headroom.pin();
    });

    // click delay for links on mobile
    this.$popup.addEventListener('click', (e) => {
        if (this.mediaQuery.matches) {
          return;
        }

        const $a = e.target.closest('a');
        if (!$a) {
          return;
        }

        e.preventDefault();
        window.setTimeout(() => window.location.href = $a.href, 150);
      });

    this.initDecorations();
	}

  setDesktopMode(state) {
    if (state) {
      this.toggle(false);
      delete this.$popup.dataset.theme;
    } else {
      this.$popup.dataset.theme = (this.$el.dataset.theme === 'senf' ? 'midnight' : 'senf');
    }

    this.$el.classList.add('no-transitions');

    requestAnimationFrame(() => {
      this.$el.classList.remove('no-transitions');
    });
  }

	get isOpen() {
		return this.$open.getAttribute('aria-expanded') === 'true';
	}

	getFocusableElements() {
		// source: https://github.com/gdkraus/accessible-modal-dialog/blob/master/modal-window.js#L38
		return [
			...this.$popup.querySelectorAll(
				[
					'a[href]',
					'area[href]',
					'input:not([disabled])',
					'select:not([disabled])',
					'textarea:not([disabled])',
					'button:not([disabled])',
					'iframe',
					'object',
					'embed',
					'*[tabindex]',
					'*[contenteditable]',
				].join(', '),
			),
		].filter(($item) => $item.offsetParent !== null); // filter out hidden elements
	}

	getFocusTrapCallback() {
    return (e) => {
      if (e.key !== 'Tab' || e.keyCode !== KEYCODE_TAB) {
				return;
			}

      const $$focusable = this.getFocusableElements();
			const $firstFocusable = $$focusable[0];
			const $lastFocusable = $$focusable[$$focusable.length - 1];
			const currentIndex = [...$$focusable].findIndex(
				($item) => $item === document.activeElement,
			);

      if (currentIndex === -1) {
        return;
      }

			if (e.shiftKey) {
				if (document.activeElement === $firstFocusable) {
					e.preventDefault();
					$lastFocusable.focus();
				} else if (currentIndex !== -1) {
					e.preventDefault();
					$$focusable[currentIndex - 1].focus();
				}
			} else {
				if (document.activeElement === $lastFocusable) {
					e.preventDefault();
					$firstFocusable.focus();
				} else if (currentIndex !== -1) {
					e.preventDefault();
					$$focusable[currentIndex + 1].focus();
				}
			}
		};
	}

	toggle(force = null) {
    const oldState = this.isOpen;
		const newState = typeof force === 'boolean' ? force : !this.isOpen;

    if (newState === oldState) {
      return;
    }

		this.$open.setAttribute('aria-expanded', newState);
		this.$close.setAttribute('aria-expanded', newState);
		this.$el.dataset.isOpen = newState.toString();

		if (newState) { // open
			window.addEventListener('click', this._closeCallback);

			// set focus to first link or if not links are there to first
      // focusable element (i.e. logo link)
      if (this.$$links.length > 0) {
        this.$$links[0].focus();
      } else if (this.getFocusableElements().length > 0) {
        this.getFocusableElements()[0]?.focus();
      }

			window.addEventListener('keydown', this._focusTrapCallback);
      disableBodyScroll(this.$popup, { reserveScrollBarGap: true });
      this.$metaThemeColor.setAttribute('content', getComputedStyle(this.$popup).getPropertyValue('background-color'));

		} else { // close

			window.removeEventListener('keydown', this._focusTrapCallback);
			window.removeEventListener('click', this._closeCallback);
      enableBodyScroll(this.$popup);

      const transitionDuration = parseFloat(getComputedStyle(this.$el).getPropertyValue('--menu--duration').replace(/s$/, ''), 10) * 1000;
      setTimeout(() => this.$metaThemeColor.setAttribute('content', this.originalThemeColor), transitionDuration);
      this.$open.focus();

		}
	}

  initDecorations() {
    this.$el.querySelectorAll(".js-menu__decoration path")
      .forEach($el => $el.style.setProperty('--length', Math.ceil($el.getTotalLength())));
  }
}

export default new Menu(document.querySelector('.js-menu'));
